import { ref, onMounted, onBeforeUnmount } from 'vue';

export default function useDataGridHover(options: {
    viewportRef: Ref<HTMLElement>,
    rowClass: string,
    isTable?: boolean
}) {

    let previousIndex: string | undefined = undefined;
    let previousNodes: HTMLElement[] = [];

    let rowClass = options.rowClass ?? 'o365-body-row';

    function removePrevious() {
        if (previousNodes.length > 0) {
            previousNodes.forEach(el => el.classList.remove('row-hover'));
            previousNodes = [];
        }
    }

    function rowQuery(previousIndex?: string, isInNewRecords = false) {
        if (options.isTable) {
            return isInNewRecords
                ? `div[data-o365-container="N"] .${rowClass}[data-o365-rowindex="${previousIndex}"]`
                : `.${rowClass}[data-o365-rowindex="${previousIndex}"]:not([data-o365-container="N"] .${rowClass})`;
        } else {
            return `.${rowClass}[data-o365-rowindex="${previousIndex}"]`;
        }
    }

    const onMouseOver = (e: MouseEvent) => {
        const target = <HTMLElement>e.target;
        window.requestAnimationFrame(() => {
            if (target == null) { return; }
            const vClosest: HTMLElement | null = target.closest(`.${rowClass}`);
            if (vClosest) {
                const newIndex = vClosest.dataset.o365Rowindex;
                if (previousIndex != newIndex) { removePrevious(); }
                previousIndex = newIndex;
                if (options.viewportRef.value == null) { return; }
                let rowsSelector = '';
                if (options.isTable) {
                    const isInNewRecords = target.closest('[data-o365-container="N"]') != null;
                    rowsSelector = rowQuery(previousIndex, isInNewRecords);
                } else {
                    rowsSelector = rowQuery(previousIndex);
                }
                options.viewportRef.value.querySelectorAll<HTMLElement>(rowsSelector).forEach((element: HTMLElement) => {
                    previousNodes.push(element);
                    element.classList.add('row-hover');
                });
            } else {
                removePrevious();
            }

        });
    }

    const onMouseLeave = (_e: MouseEvent) => {
        window.requestAnimationFrame(() => {
            removePrevious();
        });
    }

    onMounted(() => {
        options.viewportRef.value.addEventListener('mouseover', onMouseOver)
        options.viewportRef.value.addEventListener('mouseleave', onMouseLeave)
    });

    onBeforeUnmount(() => {
        options.viewportRef.value.removeEventListener('mouseover', onMouseOver)
        options.viewportRef.value.removeEventListener('mouseleave', onMouseLeave)
    });
}

type Ref<T> = {
    value: T
};
const heightMap: Record<string, Record<number, Record<string, {height: number, element: HTMLElement}> & { updater: HeightUpdater }>> = {};
export function useRowHeightSync(pOptions: {
    key: string,
    index: number,
    pinned: 'left' | 'right' | 'center' | '' | undefined | null,
}) {
    const key = pOptions.key;
    const index = pOptions.index;
    const pinned: 'left' | 'center' | 'right' = pOptions.pinned || 'center';
    const elRef: Ref<HTMLElement> = ref(null);
    
    const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
            // const currentHeight = entry.contentRect.height;
            const currentHeight = +(entry.borderBoxSize[0].blockSize).toFixed(2);
            // const currentHeight = entry.target.scrollHeight;

            heightMap[key][index][pinned].height = currentHeight;
            // heightMap[key][index].updater.execute(pinned, currentHeight);
            // heightMap[key][index]['left'].style.height = currentHeight + 'px';
            // heightMap[key][index]['center'].style.height = currentHeight + 'px';
            // heightMap[key][index]['right'].style.height = currentHeight + 'px';
            console.log(currentHeight, index);

        }
    });
    onMounted(() => {
        if (elRef.value) {
            if (heightMap[key] == null) { heightMap[key] = {}; }
            if (heightMap[key][index] == null) {
                (heightMap[key][index] as any) = {
                    updater: new HeightUpdater(key, index)
                };
            }
            heightMap[key][index][pinned] = {
                element: elRef.value,
                height: elRef.value.clientHeight
            };
            resizeObserver.observe(elRef.value);
        }
    });

    onBeforeUnmount(() => {
        resizeObserver.disconnect();
        if (heightMap[key] == null) { return; }
        delete heightMap[key][index][pinned];
    });
    return elRef;
}

class HeightUpdater {
    private _key: string;
    private _index: number;
    private _running = false;
    private _activeUpdate = false;
    private _height: number = 0;

    private _newHeights: {
        left?: number,
        center?: number,
        right?: number,
    } = {};

    private _debounce: number | null = null;

    constructor(pKey: string, pIndex: number) {
        this._key = pKey;
        this._index = pIndex;
    }

    execute(pPin: 'left'|'center'|'right', pHeight: number) {
        if (this._running) { return; }
        this._newHeights[pPin] = pHeight;
        if (this._debounce) {
            window.clearTimeout(this._debounce);
        }

        this._debounce = window.setTimeout(() => {
            this._running = true;
            const end = () => {
                this._newHeights = {};
                this._running = false;
                this._debounce = null;
            };

            console.log('get max height', this._newHeights);
            const height = Math.max(heightMap[this._key][this._index]['left'].height, heightMap[this._key][this._index]['center'].height, heightMap[this._key][this._index]['right'].height)
            if (height === this._height && !this._activeUpdate) {
                this._activeUpdate = true;
                heightMap[this._key][this._index]['left'].element.style.minHeight = '';
                heightMap[this._key][this._index]['center'].element.style.minHeight = '';
                heightMap[this._key][this._index]['right'].element.style.minHeight = '';
                end();
                return;
            }

            heightMap[this._key][this._index]['left'].element.style.minHeight = height + 'px';
            heightMap[this._key][this._index]['center'].element.style.minHeight = height + 'px';
            heightMap[this._key][this._index]['right'].element.style.minHeight = height + 'px';
            this._height = height;
            this._activeUpdate = false;

            end();
        }, 10);
    }
}